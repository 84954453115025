var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('translation-locales'), _c('v-card-text', [!_vm.selectedData ? _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "items": _vm.typeOptions,
      "item-text": "name",
      "item-value": "value",
      "label": "Select Alternative Type",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.alternative.data_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form.alternative, "data_type", $$v);
      },
      expression: "form.alternative.data_type"
    }
  }) : _vm._e(), _vm.form.alternative.data_type === 'category' ? _c('div', {
    staticClass: "d-flex"
  }, [!_vm.selectedData ? [_c('SearchCategory', {
    staticClass: "mr-2",
    on: {
      "records": function records(_records) {
        return _vm.dataOptions = _records.map(function (cat) {
          return {
            name: cat.key,
            value: cat
          };
        });
      }
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.dataOptions,
      "item-text": "name",
      "item-value": "value",
      "label": "Category",
      "outlined": "",
      "rules": [_vm.required]
    },
    on: {
      "input": _vm.updatedDataOption
    },
    model: {
      value: _vm.selectedData,
      callback: function callback($$v) {
        _vm.selectedData = $$v;
      },
      expression: "selectedData"
    }
  })] : _c('div', {
    staticClass: "mb-5 mt-5"
  }, [_c('p', [_vm._v("Adding alternative replacement for "), _c('b', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.selectedData.name.en))])])])], 2) : _vm._e(), _vm.selectedData ? [_c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Unique Dictionary Key",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.dictionary.key,
      callback: function callback($$v) {
        _vm.$set(_vm.form.dictionary, "key", $$v);
      },
      expression: "form.dictionary.key"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.distributorOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Select Distributor to see it",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.dictionary.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form.dictionary, "distributor_id", $$v);
      },
      expression: "form.dictionary.distributor_id"
    }
  }), _c('translatable-input', {
    attrs: {
      "label": "Replace Value",
      "allow-empty": ""
    },
    model: {
      value: _vm.form.dictionary.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form.dictionary, "value", $$v);
      },
      expression: "form.dictionary.value"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Notes",
      "hint": "optional.",
      "outlined": ""
    },
    model: {
      value: _vm.form.dictionary.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.form.dictionary, "notes", $$v);
      },
      expression: "form.dictionary.notes"
    }
  })] : _vm._e()], 2), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading,
      "disabled": !_vm.form.dictionary.distributor_id
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }